import { ApiResponse } from '../models/ApiResponse';
import { ClientModule, ClientModuleSummary } from '../models/ClientModule';
import { Descriptor } from '../models/Descriptor';
import { IndexListItem } from '../models/Document';
import { FormType } from '../models/FormTypes';
import { ClientModuleTemplateFormInfoResponse } from '../models/Module';
import BaseService from './BaseService';

type ClientModuleTemplateFormFilter = {
  clientModuleId?: string;
  latestOnly?: boolean;
  includeArchived?: boolean;
  types?: (typeof FormType)[keyof typeof FormType][];
  searchTerm?: string;
  pageSize?: number;
  pageNumber?: number;
};

export default class ModuleService extends BaseService {
  public static createModule(clientId: string, templateModuleId: string): Promise<ApiResponse<ClientModule>> {
    return this.post('/v1/modules', {
      templateModuleId,
      clientId,
    });
  }

  public static getModule(id: string): Promise<ApiResponse<ClientModule>> {
    return this.get(`/v1/modules/${id}`);
  }

  public static getModuleInfo(id: string): Promise<ApiResponse<Descriptor>> {
    return this.get(`/v1/modules/${id}/info`);
  }

  public static getModules(): Promise<ApiResponse<ClientModuleSummary[]>> {
    return this.get(`/v1/modules`);
  }

  public static getFormTemplates(
    clientId: string,
    filter?: ClientModuleTemplateFormFilter,
  ): Promise<ApiResponse<ClientModuleTemplateFormInfoResponse[]>> {
    return this.get(`/v1/modules/form-templates`, { params: { clientId: clientId, ...filter } });
  }

  public static deleteModule(moduleId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/modules/${moduleId}`);
  }

  public static getIndexes(clientModuleId: string, pageNumber: number, pageSize: number): Promise<ApiResponse<IndexListItem[]>> {
    return this.get(`/v1/modules/${clientModuleId}/indexes`, { params: { pageNumber: pageNumber, pageSize: pageSize } });
  }

  public static async downloadIndexes(clientModuleId: string, indexId?: string): Promise<Blob> {
    const res = await this.get<Blob>(`/v1/modules/${clientModuleId}/indexes/pdf`, {
      params: { indexId: indexId },
      responseType: 'arraybuffer',
    });

    if (res.status !== 200) {
      throw res.data;
    }

    const fileContent = res.data;
    const fileType = res.headers['content-type'];

    return new Blob([fileContent], { type: fileType });
  }
}
